export const getStrapiImage = (image) => {
  return image?.data?.attributes?.url || "";
};

export const getStrapiMimetype = (file) => {
  return file?.data?.attributes?.mime || "";
};

export const getStrapiFileType = (file) => {
  const mime = file?.data?.attributes?.mime.split('/'); 
  return mime[0] || "";
};